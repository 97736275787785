import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export default function MyForm({setRecaptchaToken}) {
	const siteKey = process.env.REACT_APP_CAPTCHA_KEY;

	const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
	};

	return (
		<ReCAPTCHA
			sitekey={siteKey}
			onChange={handleRecaptchaChange}
		/>
	);
}
